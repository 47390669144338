import './App.scss';
import {
  Routes,
  Route
} from "react-router-dom";
import Login from './login';
import Main from './main';

function App() {
  return (
    <div className="main-app">
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
