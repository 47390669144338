export const LOGIN_BY_MAIL = 'Login by mail';
export const COMPANY = 'Company';
export const ORGANIZATION_NAME = 'Organization name';
export const PASSWORD = 'Password';
export const SIGN_IN = 'Sign in';
export const MISSING_DETAILS = 'Missing details';
export const USER_NAME = 'User name';
export const PHONE_NUMBER = 'Phone number';
export const LOGOUT = 'Logout';
export const BACK = 'Back';
export const MANAGED_SESSIONS_FOR = 'Managed Sessions for';