import React, { useEffect, useState, useCallback } from 'react';
import './index.scss';
import { ENUM } from '../enum';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Table,
    Button,
    Badge,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import * as STRINGS from '../strings/english';
import LogoutButton from './logout-btn';


const initialLoginData = {
    userId: 0,
    userType: '',
    allowedUsersNumbers: [],
    allowedNumbersSessions: {},
}


export default function Main() {

    const [serversData, setServersData] = useState([]);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const [serversUrl, setServersUrl] = useState([]);
    const [loginData, setLoginData] = useState(initialLoginData);
    const [iframeUrlData, setIframeUrlData] = useState({});

    const [open, setOpen] = useState('1');

    const location = useLocation();
    const navigate = useNavigate();

    const { state } = location;

    useEffect(() => {
        if (!state) {
            navigate('/login');
        }
    })

    const sortDataInArray = () => {
        let arr = [];
        let serversUrlArr = [];
        for (const key in state.allowedNumbersSessions) {
            arr.push({
                subDomainName: key,
                sessions: state.allowedNumbersSessions[key],
            });
            serversUrlArr.push(`https://${key}${ENUM.DOMAIN}`);
        }
        setServersUrl(serversUrlArr);
        return arr;
    }

    const stableSortDataInArray = useCallback(sortDataInArray, [state]);

    useEffect(() => {
        if (state) {
            setLoginData({
                userId: state.userId,
                userType: state.userType,
                allowedUsersNumbers: state.allowedUsersNumbers,
                allowedNumbersSessions: state.allowedNumbersSessions,
            });
            let data = stableSortDataInArray();
            setServersData(data);
            setIframeUrlData({
                userId: state.userId,
                userType: state.userType,
            })
        }
    }, [state, stableSortDataInArray])

    const logout = () => {
        localStorage.clear();
        window.history.replaceState({}, document.title);
        navigate('/login');
    }

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const serverLinkOnclick = (elm) => {
        let url = `https://${elm.subDomainName}${ENUM.DOMAIN}/admin-screen?data=${JSON.stringify(iframeUrlData)}`;
        setIframeUrl(url);
        setShowIframe(true);
    }

    const iframeOnLoad = () => {
        const x = document.getElementById("router-spikko-wa-iframe");
        if (x) {
            const iframeData = {
                username: localStorage.getItem('email'),
                token: localStorage.getItem('auth-data'),
                ...loginData,
            }
            x.contentWindow.postMessage(iframeData, iframeUrl.split('/admin')[0]);
        }
    }

    if (state &&
        (state.userType === ENUM.WA_API_TYPE ||
            state.userType === ENUM.AGENT_TYPE)) {
        return (
            <div className='dash-table-main'>
                {!showIframe &&
                    <div className='dash-table-header'>
                        <LogoutButton logout={logout} />
                        <h5 className='dash-table-header-text'>
                            {`${STRINGS.MANAGED_SESSIONS_FOR} `}
                            <span className='dash-table-header-username'>
                                {localStorage.getItem('email')}
                            </span>
                        </h5>
                    </div>}
                {!showIframe ?
                    <div className='dash-main'>
                        <Accordion open={open} toggle={toggle}>
                            {serversData.map((elm, i) => {
                                const index = i + 1;
                                return (
                                    <AccordionItem
                                        key={`accordion-item-${i}`}
                                    >
                                        <AccordionHeader
                                            targetId={index.toString()}
                                        >
                                            <span className='dash-accordion-header'>
                                                {`${elm.subDomainName}${ENUM.DOMAIN}`}
                                            </span>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={index.toString()}>
                                            <div
                                                className='dash-server-link-main'
                                                onClick={() => serverLinkOnclick(elm)}
                                            >
                                                <span className='dash-server-link-src'>
                                                    {`${elm.subDomainName}${ENUM.DOMAIN}`}
                                                </span>
                                                <span className='dash-server-link-icon'>
                                                    <img
                                                        alt='download-icon'
                                                        src='assets/external-link.svg'
                                                    />
                                                </span>
                                            </div>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            #
                                                        </th>
                                                        <th>
                                                            {STRINGS.USER_NAME}
                                                        </th>
                                                        <th>
                                                            {STRINGS.PHONE_NUMBER}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {elm.sessions.map((s, i) => {
                                                        return (
                                                            <tr key={`session-row-${i}`}>
                                                                <th scope="row">
                                                                    {i + 1}
                                                                </th>
                                                                <td>
                                                                    {s.Username}
                                                                </td>
                                                                <td>
                                                                    {s.PhoneNumber}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </AccordionBody>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </div> :
                    <div className='dash-iframe-main'>
                        <div className='dash-iframe-side-bar'>
                            <LogoutButton logout={logout} />
                            <Button
                                className='dash-iframe-back-btn'
                                onClick={() => {
                                    setShowIframe(false);
                                }}
                                color=''
                            >
                                <div
                                    className='dash-iframe-back-btn-content-div'>
                                    {STRINGS.BACK}
                                    <img
                                        alt='back-icon'
                                        src='assets/arrow-left.svg'
                                        className='dash-iframe-back-icon'
                                    />
                                </div>
                            </Button>
                            <div className='dash-iframe-side-bar-links-main'>
                                {serversUrl.map((elm, i) => {
                                    return (
                                        <div
                                            key={`server-url-list-${i}`}
                                        >
                                            <h4>
                                                <Badge
                                                    className={
                                                        `dash-iframe-side-bar-link ${iframeUrl.startsWith(elm) ? 'bold-link' : 'regular-link'}`
                                                    }
                                                    color={''}
                                                    onClick={() => {
                                                        setIframeUrl(`${elm}/admin-screen?data=${JSON.stringify(iframeUrlData)}`);
                                                    }}
                                                >
                                                    {elm}
                                                </Badge>
                                            </h4>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <iframe
                            id='router-spikko-wa-iframe'
                            title='spikko-wa'
                            src={iframeUrl}
                            className='dash-iframe'
                            onLoad={iframeOnLoad}
                        />
                    </div>}
            </div>
        )
    }
}
