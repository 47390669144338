import React, { useState } from 'react'
import './index.scss';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
} from 'reactstrap';
import * as STRINGS from '../strings/english';
import axios from 'axios';
import { ENUM } from '../enum';
import { useNavigate } from 'react-router-dom';


export default function Login(props) {

    const navigate = useNavigate();

    const [isLoader, setIsLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');


    const login = () => {
        if (email && password) {
            setIsLoader(true);
            axios.post(`${process.env.REACT_APP_SPIKKO_API}/Provisioning/WALogin`, {
                "username": email.toLowerCase(),
                "password": password
            }).then(response => {
                if (response && response.data) {
                    setIsLoader(false);
                    if (response.data.UserType === ENUM.WA_API_TYPE ||
                        response.data.UserType === ENUM.AGENT_TYPE) {
                        localStorage.setItem('email', email.toLowerCase());
                        localStorage.setItem('auth-data', response.data.HashedPassword);
                        navigate(
                            '/',
                            {
                                state: {
                                    userType: response.data.UserType,
                                    allowedUsersNumbers: response.data.AllowedUsersNumbers,
                                    userId: response.data.UserId,
                                    allowedNumbersSessions: response.data.AllowedNumbersSessions,
                                }
                            }
                        );
                    } else if (response.data.UserType === ENUM.REGULAR_TYPE) {
                        const url = `https://${response.data.RecommendedLogin}${ENUM.DOMAIN}/login?token=${encodeURIComponent(response.data.HashedPassword)}&username=${email.toLowerCase()}`;
                        window.location.replace(url);
                    }
                }
            }).catch(error => {
                setIsLoader(false);
                alert(error.response
                    ? error.response.data.Description || error.response.data.Message : error);
            });
        } else {
            alert(STRINGS.MISSING_DETAILS);
        }
    }

    return (
        <div className="login-main">
            <Form className="login-form">
                <h4>{STRINGS.LOGIN_BY_MAIL}</h4>
                <FormGroup>
                    <Label for="login-input-email">
                        {`${STRINGS.COMPANY} / ${STRINGS.ORGANIZATION_NAME}`}
                    </Label>
                    <Input
                        type="email"
                        name="login-input-email"
                        id="login-input-email"
                        onChange={e => setEmail(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="login-input-pass">
                        {STRINGS.PASSWORD}
                    </Label>
                    <Input
                        type="password"
                        name="login-input-pass"
                        id="login-input-pass"
                        onChange={e => setPassword(e.target.value)}
                    />
                </FormGroup>
                <Button
                    className='login-signin-btn'
                    id='login-signin-btn'
                    onClick={login}
                    size="lg"
                    block
                >
                    {isLoader ?
                        <Spinner className='login-spinner' />
                        : STRINGS.SIGN_IN}
                </Button>
            </Form>
        </div>
    )
}
