import React from 'react';
import './logout-btn.scss';
import { Button } from 'reactstrap';
import * as STRINGS from '../strings/english';

function LogoutButton(props) {
    return (
        <Button
            onClick={props.logout}
            color=''
            className='logout-btn'
        >
            <div className='logout-btn-content-div'>
                {STRINGS.LOGOUT}
                <img
                    alt='logout-icon'
                    src='assets/box-arrow-right.svg'
                    className='logout-icon'
                />
            </div>
        </Button>
    )
}

export default LogoutButton;